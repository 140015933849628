import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import figureFormatter from "@/services/utils/figureFormatter";
import { useStore } from "vuex";
import useDate from '@/services/utils/day';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const pdfPrinter = () => {
    const { formatDate } = useDate();
    const store = useStore();
    const { commaFormat } = figureFormatter();

    const userName = store.state.user.user.name;

    const exportToPDF = (company, userCollection, date, payload) => {

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 30, 100, 30, 80 ],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(userCollection, date, payload),

            styles : {
                header: {
                    fontSize: 24
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: 'User Collection Summary Report'
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (userCollection, date, payload) => {
        return [
            {
                text: 'User Collection Summary Report',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },
            {
                stack: [
                    {
                        text: `Date: ${date.startDate} - ${date.endDate}`
                    },
                    {
                        text: `${payload.billType ? 'Bill type: ' + payload.billType : ''}`
                    },
                    {
                        text: `${payload.createdBy ? 'Created By: ' + payload.createdBy : ''}`
                    },
                ]
            },
            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['auto', 'auto', 'auto', 'auto'],
                    body: getTableBody(userCollection)
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
        ]
    }

    const getTableBody = (userCollection) => {
        const header = getTableHead();
        const row = getTableRow(userCollection);
        return [
            header,
            ...row
        ]
    }

    const getTableRow = (userCollection) => {
        const data = [];
        userCollection.map((child) => {
            const row = [
                { text: child.name },
                { text: commaFormat(child.total_paid_amount), alignment: 'right' },
                { text: commaFormat(child.refund_amount), alignment: 'right' },
                { text: commaFormat(child.total_paid_amount - child.refund_amount), alignment: 'right' }
            ];
            data.push(row);
        })

        const totalRow = [
            { text: `Total (${userCollection.length})`, alignment: 'right' },
            { text: commaFormat(getTotalAmount(userCollection, 'total_paid_amount')), alignment: 'right' },
            { text: commaFormat(getTotalAmount(userCollection, 'refund_amount')), alignment: 'right' },
            { text: commaFormat(getTotalAmount(userCollection, 'total_paid_amount') - getTotalAmount(userCollection, 'refund_amount')), alignment: 'right' },
        ];

        data.push(totalRow);
        return data
    }

    const getTotalAmount = (userCollection, column) => {
        return userCollection.reduce((total, amount) => total + amount[column], 0);
    }

    const getTableHead = () => {
        return [
            { text: 'RECEIPT BY' },
            { text: 'RECEIVED AMOUNT' , alignment: 'right' },
            { text: 'REFUND AMOUNT' , alignment: 'right' },
            { text: 'NET COLLECTION' , alignment: 'right' },
        ]
    }

    const getHeader = (company) => {
        return {
            margin: [ 30, 25, 30, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60,
                    maxWidth: 110
                },
                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }

    const getFooter = (userName) =>{
        return {
            margin: [ 25, 0 ],
            columns: [
                {
                    columns: [
                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },
                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;
