<template>
    <div class="card">
        <div class="table-responsive">
            <table class="table nowrap">
                <thead>
                    <tr>
                        <th>Receipt By</th>
                        <th class="text-right">Received Amount</th>
                        <th class="text-right">Refund Amount</th>
                        <th class="text-right">Net Collection</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="list in lists" :key="list.id">
                        <td>{{ list.name }}</td>
                        <td class="text-right">{{ commaFormat(list.total_paid_amount) }}</td>
                        <td class="text-right">{{ commaFormat(list.refund_amount) }}</td>
                        <td class="text-right">{{ commaFormat(list.total_paid_amount-list.refund_amount) }}</td>
                    </tr>
                    <tr class="bg-light-secondary">
                      <th class="text-right">Total ({{ props.lists.length }})</th>
                      <th class="text-right">{{commaFormat(getTotalAmount('total_paid_amount'))}}</th>
                      <th class="text-right">{{commaFormat(getTotalAmount('refund_amount'))}}</th>
                      <th class="text-right">{{commaFormat(getTotalAmount('total_paid_amount') - getTotalAmount('refund_amount'))}}</th>
                    </tr>
                </tbody>
            </table>
          <p v-if="!props.lists.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";

const props = defineProps({
    lists: Array
})

const { commaFormat } = figureFormatter();

const getTotalAmount = (column) => {
  return props.lists.reduce((total, amount) => total + amount[column], 0);
}
</script>

<style scoped>
    .nowrap{
        white-space: nowrap;
    }
</style>
