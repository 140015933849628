<template>
  <div class="card">

    <div class="bg-blue-light p-1">
      <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="User Collection Summary Report"
      />
    </div>

    <div class="my-2 px-2">
      <DateQuerySetter
          btnTitle="Go"
          @onClickGo="getReport"
      >
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Created By:</label>
          <v-select multiple placeholder="Created By" :options="contactList" label="name"
                    :reduce="(name) => name.id" v-model="updatedBy"/>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Receipt type: </label>
          <v-select
              placeholder="Select Bill Type"
              v-model="billType"
              :options="billTypeList"
              label="label"
              :reduce="label => label.value"
              multiple
          />
        </div>
      </DateQuerySetter>
    </div>

    <div class="d-flex justify-content-end px-2 mb-2">
      <button class="btn btn-primary btn-block waves-effect waves-float waves-light print-btn" @click="goToPrint">
        Print
      </button>
    </div>

    <div class="col-12 px-2">
      <ListTable :lists="lists"/>
    </div>
    <div class="mb-2"></div>

    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
    <Loader v-if="loading"/>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import {useRoute, useRouter} from 'vue-router';
import DateQuerySetter from '@/components/atom/DateQuerySetter';
import ListTable from '@/components/molecule/company/hospital/hospital-admin/UserCollectionSummaryReportTable.vue';
import handleHospitalReport from "@/services/modules/hospital/report";
import {computed, inject, onMounted, ref} from "vue";
import Loader from "@/components/atom/LoaderComponent.vue";
import Pagination from "@/components/atom/Pagination.vue";
import {useStore} from "vuex";
import handleCompany from "@/services/modules/company";
import pdfPrinter from '@/services/utils/pdf/userCollectionSummaryReport';
import handleImportTracker from "@/services/modules/procurement/importTracker";
import handleInventory from "@/services/modules/inventory";

const {fetchHome} = handleInventory()
const {fetchContacts} = handleImportTracker();
const {fetchUserCollectionSummary} = handleHospitalReport()
const {fetchCompanyInfo} = handleCompany();
const {exportToPDF} = pdfPrinter();

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const store = useStore()
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const contactList = ref([])
const billTypeList = ref([])
const lists = ref([])
const userCollection = ref([]);
let offset = ref(100)
const updatedBy = ref(null)
const billType = ref(null)
const companyInfo = ref({})
const isPaginated = ref(true)
//computed
const companyId = computed(() => route.params.companyId);
const startDate = computed(() => route.query.start);
const endDate = computed(() => route.query.end);
const page = computed(() => route.query.page);

//methods
const goToPrint = async () => {

  const payloads = {
    createdBy: '',
    billType: !!billType.value ? billType.value : ''
  };

  if (!!updatedBy.value && updatedBy.value.length > 0) {
    const userNames = updatedBy.value.map(id => {
      const user = contactList.value.find(item => item.id === id);
      return user ? user.name : '';
    }).filter(name => name !== ''); // Filter out any empty names

    payloads.createdBy = userNames.join(', ');
  }

  const date = {startDate: startDate.value, endDate: endDate.value};
  loading.value = true;

  await getReport(false).then(() => {
    if (!userCollection.value.length) return;
    exportToPDF(companyInfo.value, userCollection.value, date, payloads)
  })
}

const getQuery = () => {
  let query = '?company_id=' + companyId.value

  if (!!updatedBy.value && updatedBy.value.length > 0) {
    query += '&updated_by=' + updatedBy.value
  }

  if (!!billType.value) {
    query += '&receipt_type=' + billType.value
  }

  if (!startDate.value && !endDate.value) return query
  if (startDate.value) query += '&start_date=' + startDate.value
  if (endDate.value) query += '&end_date=' + endDate.value
  if (page.value) query += '&page=' + page.value
  if (isPaginated.value) query += '&is_paginated=' + isPaginated.value
  query += '&offset=' + offset.value
  return query
};

const getReport = async (pagination = true) => {
  isPaginated.value = pagination;

  await fetchUserCollectionSummary(getQuery()).then(({status, data}) => {
    if (!status) {
      lists.value = []
      userCollection.value = []
      return resetPagination()
    }

    if (!isPaginated.value) {
      userCollection.value = data;
      return;
    }

    lists.value = data.data
    if (data.data.length > 0) {
      setPagination(data)
    }
  }).catch((err) => {
    loading.value = false
  }).finally(() => {
    loading.value = false
  })
};

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};
const resetPagination = () => {
  store.commit('resetPagination')
};

const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

const onPageChange = async (page) => {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  await router.push({path: route.path, query: routeQuery})
  await getReport()
}

onMounted(async () => {
  loading.value = true

  const companyQuery = `?company_id=${companyId.value}`

  await Promise.all([
    fetchCompanyInfo(companyId.value).then((res) => {
      companyInfo.value = res.data
    }),
    fetchContacts(companyQuery).then(({status, data}) => {
      if (status) contactList.value = data
    })
  ]).then(() => {
    loading.value = false;
  }).catch(() => {
    loading.value = false
  });

  fetchHome().then(res => {
    if (res.data) {
      for (const value of Object.keys(res.data.hospital_money_receipt_types)) {
        billTypeList.value.push({
          value: value,
          label: res.data.hospital_money_receipt_types[value]
        })
      }
    }
  })
})
</script>

<style scoped>
.print-btn {
  margin-right: 1% !important;
}
</style>
